import React, { useRef } from "react";
import useScrollVisibility from "../useScrollVisibility";

const Skills = () => {
  const skillsRef = useRef(null);
  const isVisible = useScrollVisibility(skillsRef);

  return (
    <section
      className={`skills ${isVisible ? "show-animate" : ""}`}
      id="skills"
      ref={skillsRef}
    >
      <h2 className="heading">
        My <span>Skills</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>

      <div className="skills-row">
        <div className="skills-column">
          <h3 className="title">
            Coding Skills
            <span className="animate scroll" style={{ "--i": 2 }}></span>
          </h3>

          <div className="skills-box">
            <div className="skills-content">
              <div className="progress">
                <h3>
                  HTML <span>90%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  CSS <span>80%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Javascript <span>65%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Python <span>80%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>
            </div>
            <span className="animate scroll" style={{ "--i": 3 }}></span>
          </div>
        </div>

        <div className="skills-column">
          <h3 className="title">
            Professional Skills
            <span className="animate scroll" style={{ "--i": 5 }}></span>
          </h3>

          <div className="skills-box">
            <div className="skills-content">
              <div className="progress">
                <h3>
                  Web Design <span>75%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Web Development <span>95%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Graphic Design <span>85%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  SEO Marketing <span>60%</span>
                </h3>
                <div className="bar">
                  <span></span>
                </div>
              </div>
            </div>

            <span className="animate scroll" style={{ "--i": 6 }}></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
