import React, { useEffect, useRef } from "react";
import { aboutImg } from "../index";
import Typed from "typed.js";
import useScrollVisibility from "../useScrollVisibility";
import { Link } from "react-router-dom";

const About = () => {
  const aboutRef = useRef(null);
  const isVisible = useScrollVisibility(aboutRef);
  useEffect(() => {
    const typed = new Typed(".multiple-text", {
      strings: [
        "IT Support Specialist",
        "Frontend Developer",
        "Backend Developer",
      ],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section
      className={`about ${isVisible ? "show-animate" : ""}`}
      id="about"
      ref={aboutRef}
    >
      <h2 className="heading">
        About <span className="animate scroll" style={{ "--i": 1 }}></span>
        <span>Me</span>
      </h2>
      <div className="about-img">
        <img src={aboutImg} alt="" />
        <span className="circle-spinner"></span>
        <span className="animate scroll" style={{ "--i": 2 }}></span>
      </div>

      <div className="about-content">
        {/* <h3>
          Frontend Developer!
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </h3> */}
        <br />
        <h3>
          <span className="multiple-text"></span>
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </h3>
        <p>
          I am a Frontend developer with Google IT Support Specialist
          certification and a passion for innovation. Driven by an insatiable
          curiosity to explore the limitless possibilities of technology, I
          excel in crafting clean and efficient code and designing seamless user
          experiences. What sets me apart is not only my technical expertise in
          troubleshooting, network security, and systems administration but also
          my commitment to innovation and problem-solving. I thrive on
          developing ideas into useful and meaningful solutions, using my wide
          skill set to address complicated situations.
          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </p>

        <div className="btn-box btns">
          <button type="button" className="btn">
            <Link
              to="/aboutMe"
              style={{ color: "inherit", textDecoration: "inherit" }}
              aria-label="About Me"
            >
              Read More
            </Link>
          </button>
          <span className="animate scroll" style={{ "--i": 5 }}></span>
        </div>
      </div>
    </section>
  );
};

export default About;
