import React from "react";
import "./detailAbout.css";
import imgAbout from "../../assets/imgAbout.png";
import {
  css,
  figma,
  git,
  javascript,
  mongodb,
  nodejs,
  reactjs,
  redux,
  tailwind,
  typescript,
} from "../../constants";
import { useNavigate } from "react-router-dom";

const DetailedAbout = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate("/");
  };
  return (
    <>
      <div className="detailsSection">
        <div className="detailAbout">
          <div className="detailAbout__container ">
            <div className="detailAbout__info">
              <div>
                <h2 className="title__left">About Me</h2>
                <p className="description">
                  Hello and welcome to my virtual space! I'm Linus Kexordzu, a
                  dedicated web developer and certified IT Support Specialist.
                  With a talent for combining inspiration and innovation, I
                  specialize in developing intuitive user interfaces and
                  optimizing IT systems for optimal efficiency.
                </p>
              </div>
              <div>
                <h2 className="title__left">Who Am I?</h2>
                <p className="description">
                  I'm a persistent optimist and passionate coder inspired by
                  technology's transformative potential. My interest in web
                  development and information technology evolved from a strong
                  desire to see ideas come to life. Whether designing appealing
                  user interfaces or diving into the complexity of computer
                  architecture, I like the creative process and the
                  transformative power of technology. My passion transcends to
                  web development and other technology fields, showing my desire
                  to create engaging digital experiences while also exploring
                  new tech solutions.
                </p>
              </div>

              {/*  */}
            </div>
            <div className="detailAbout__spinner detailAbout__img">
              <img
                className="detailAbout__spinner-img"
                src={imgAbout}
                alt="my_photo"
              />
              <span className="circle-spinner"></span>
            </div>
          </div>
          {/*  */}

          {/*  */}

          <div className="detailAbout__info-card">
            <h2 className="title flex__center">My Journey</h2>
            <p className="description">
              My journey into the world of technology has been both tough and
              fulfilling. It all started with a fascination with technology,
              which led me to delve into the nuances of programming languages.
              I've gone from an inquisitive explorer to a diligent student, and
              now I'm an experienced developer and certified IT Support
              Specialist. Learning cutting-edge technology and contributing to
              open-source platforms have been transformative experiences that
              have greatly enhanced my development. These experiences not only
              improved my technical skills but also established in me a deep
              respect for the art of problem-solving.
            </p>

            <h2 className="title flex__center">What Drives Me</h2>
            <p className="description">
              I'm motivated by the belief that technology can revolutionize both
              businesses and lifestyles. It’s not just about writing lines of
              code; it’s about creating solutions that empower, inspire, and
              simplify. Whether it’s crafting elegant user interfaces,
              optimizing algorithms, or architecting scalable IT systems as a
              certified IT Support Specialist, I approach each task with
              unwavering enthusiasm.
            </p>
          </div>
        </div>
      </div>
      <section className="box">
        <div>
          <div className="description">
            <h2 className="title flex__center">Technologies & Skillset</h2>
            <div className="skillset__container">
              <div className="skillset__box">
                <h1>Coding Languages</h1>
                <p>
                  I'm proficient in JavaScript (with a focus on using React),
                  Python, CSS, HTML and I'm always enthusiastic about learning
                  more.
                </p>
              </div>

              <div className="skillset__box">
                <h1>Web Development</h1>{" "}
                <p>
                  Front-end or back-end, I thrive in creating web experiences
                  that are not only visually appealing but also highly
                  functional.
                </p>
              </div>

              <div className="skillset__box">
                <h1>Problem Solver</h1>
                <p>
                  I relish challenges and have a knack for dissecting complex
                  issues to find elegant solutions. I don't shy away from
                  putting in the time needed to get solutions either.
                </p>
              </div>

              <div className="skillset__box">
                <h1>IT Support</h1>
                <p>
                  Boasting a broad skill set involving computer architecture,
                  networking, operating systems (Windows, Linux), software
                  management, systems administration, and IT security.
                </p>
              </div>

              {/*  */}
            </div>
            <div className="detailAbout__icons">
              <img src={reactjs} alt="reactjs" />
              <img src={typescript} alt="ts" />
              <img src={figma} alt="figma" />
              <img src={git} alt="git" />
              <img src={nodejs} alt="node" />
              <img src={redux} alt="redux" />
              <img src={javascript} alt="js" />
              <img src={tailwind} alt="tailwind" />
              <img src={mongodb} alt="mongodb" />
              <img src={css} alt="css" />
            </div>
          </div>
        </div>
        <div className="">
          <h2 className="title flex__center">Beyond Code</h2>
          <p className="description">
            While I'm deeply passionate about technology, I'm also a guy who
            loves nature and outdoor activities, sports and music. These
            interests fuel my creativity, provide a fresh perspective, and keep
            me grounded.
          </p>
        </div>
        <div className="">
          <h2 className="title flex__center">Let's Connect</h2>
          <p className="description">
            I’m excited about the future in the ever-evolving tech landscape.
            Whether you’re seeking a developer to bring your vision to life, a
            collaborator on an exciting project, or simply want to discuss the
            latest tech trends, I’d love to connect. As a software developer and
            certified IT Support Specialist, I’m equipped to tackle diverse
            challenges—from crafting innovative solutions to optimizing IT
            systems. Feel free to contact me, and let’s embark on a journey of
            innovation together! Thank you for exploring my portfolio, and I
            look forward to connecting with you.
          </p>
        </div>
        <div className="button_box flex__center">
          <div className="btn-box">
            <a
              href="#contact"
              className="btn"
              onClick={() => handleClick("/", "contact")}
              aria-label="Go to Contact"
            >
              Hire Me
            </a>
            <a
              href="#contact"
              className="btn"
              onClick={() => handleClick("/", "contact")}
              aria-label="Contact Me"
            >
              Let's Talk
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailedAbout;
