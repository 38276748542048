import { useState, useEffect, useRef } from "react";
import { FiArrowUp } from "react-icons/fi";

const useScrollVisibility = (ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScrollForFooter = () => {
      if (ref.current) {
        const scrolledFromTop = window.scrollY + window.innerHeight;
        const differenceFromBottom =
          document.documentElement.scrollHeight - scrolledFromTop;

        if (differenceFromBottom <= 50) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    const handleScroll = () => {
      handleScrollForFooter();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return isVisible;
};

const Footer = () => {
  const footerRef = useRef(null);
  const isVisible = useScrollVisibility(footerRef);
  return (
    <footer
      className={`footer ${isVisible ? "show-animate" : ""}`}
      id="footer"
      ref={footerRef}
    >
      <div className="footer-text">
        <p>Copyright &copy; 2023 by Linus Kexordzu | All Right Reserved.</p>

        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </div>

      <div className="footer-iconTop">
        <a href="#home" aria-label="Scroll to Top">
          <i>
            <FiArrowUp />
          </i>
        </a>

        <span className="animate scroll" style={{ "--i": 3 }}></span>
      </div>
    </footer>
  );
};

export default Footer;
