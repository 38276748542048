import React from "react";
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";
import { cvFile } from "..";

const Home = () => {
  return (
    <section className="home show-animate" id="home">
      <div className="home-content">
        <h1>
          Hi, I'm <span>Linus Kexordzu</span>
          <span className="animate" style={{ "--i": 1 }}></span>
        </h1>

        <div className="text-animate">
          <h3>Web Developer</h3>
          <span className="animate" style={{ "--i": 3 }}></span>
        </div>
        <p>
          Welcome to my website where I showcase my skills as a web developer
          and certified IT Support Specialist. I am passionate about creating
          engaging web experiences, from developing intuitive user interfaces to
          optimizing website performance and solving complex coding challenges.
          I thrive on tackling any technical challenge that comes my way.
          Collaboration with designers and developers is a key aspect of my
          approach, as I believe it enhances creativity and ensures high-quality
          outcomes!
          <span className="animate" style={{ "--i": 4 }}></span>
        </p>
        <div className="btn-box">
          <a href="#contact" aria-label="Go to Contact" className="btn">
            Let's Talk
          </a>
          <a
            href={cvFile}
            aria-label="Download My CV"
            download="LinusKexordzu.pdf"
            className="btn"
          >
            My CV
          </a>
          <span className="animate" style={{ "--i": 5 }}></span>
        </div>
      </div>

      <div className="home-sci">
        <a
          href="https://www.facebook.com/broslinus"
          aria-label="Visit my Facebook Profile"
        >
          <i>
            <FiFacebook />
          </i>
        </a>
        <a
          href="https://twitter.com/Okito_Uno"
          aria-label="Visit my Twitter Profile"
        >
          <i>
            <FiTwitter />
          </i>
        </a>
        <a
          href="https://www.linkedin.com/in/linus-kexordzu/"
          aria-label="Visit my Linkedin Profile"
        >
          <i>
            <FiLinkedin />
          </i>
        </a>
        <span className="animate" style={{ "--i": 6 }}></span>
      </div>

      <div className="home-imgHover"></div>
      <span className="animate home-img" style={{ "--i": 7 }}></span>
    </section>
  );
};

export default Home;
