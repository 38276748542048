import React, { useRef, useState } from "react";
import useScrollVisibility from "../useScrollVisibility";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const contactRef = useRef(null);
  const isVisible = useScrollVisibility(contactRef);

  // State variables for form fields
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      to_name: "Linus",
      from_name: fullName,
      from_email: email,
      mobileNumber,
      emailSubject,
      message,
    };

    // Send email using emailjs
    emailjs
      .send(
        "service_ig4g24n",
        "template_1ipiow4",
        formData,
        "FEkrEmPNdBLdaGVDx"
      )
      .then(
        (response) => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          console.log("Email sent successfully!", response);

          setFullName("");
          setEmail("");
          setMobileNumber("");
          setEmailSubject("");
          setMessage("");
        },
        (error) => {
          setLoading(false);
          console.error("Email failed to send:", error);

          alert("Something went wrong.", "Please try again.");
        }
      );
  };

  return (
    <section
      className={`contact ${isVisible ? "show-animate" : ""}`}
      id="contact"
      ref={contactRef}
    >
      <h2 className="heading">
        Contact <span>Me!</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>

      <form onSubmit={handleSubmit}>
        <div className="input-box">
          <div className="input-field">
            <input
              name="fullName"
              type="text"
              placeholder="Your Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              autoComplete="name"
            />
            <span className="focus"></span>
          </div>
          <div className="input-field">
            <input
              name="email"
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
            />
            <span className="focus"></span>
          </div>

          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>

        <div className="input-box">
          <div className="input-field">
            <input
              name="mobileNumber"
              type="number"
              placeholder="Mobile number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              autoComplete="tel"
            />
            <span className="focus"></span>
          </div>
          <div className="input-field">
            <input
              name="emailSubject"
              type="text"
              placeholder="Email Subject"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
              required
              autoComplete="off"
            />
            <span className="focus"></span>
          </div>

          <span className="animate scroll" style={{ "--i": 5 }}></span>
        </div>

        <div className="textarea-field">
          <textarea
            name="message"
            cols="30"
            rows="10"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            autoComplete="off"
          />
          <span className="focus"></span>

          <span className="animate scroll" style={{ "--i": 7 }}></span>
        </div>

        <div className="btn-box btns">
          <button type="submit" className="btn">
            {loading ? "Sending..." : "Send"}
          </button>
          <span className="animate scroll" style={{ "--i": 9 }}></span>
        </div>
      </form>
    </section>
  );
};

export default Contact;
