import React, { useRef } from "react";
import useScrollVisibility from "../useScrollVisibility";
import { FaGithub } from "react-icons/fa";
import { HiMiniGlobeAmericas } from "react-icons/hi2";
import {
  display1,
  display1Webp,
  display2,
  display2Webp,
  display3,
  display3Webp,
  display4,
  display4Webp,
  display5,
  display5Webp,
  figma,
  figmaWebp,
} from "..";

import "./portfolio.css";

const Portfolio = () => {
  const portfolioRef = useRef(null);
  const isVisible = useScrollVisibility(portfolioRef);

  const supportsWebP = () => {
    const elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d"))) {
      return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
    return false;
  };

  const renderImage = (webpSrc, fallbackSrc) => {
    return supportsWebP() ? webpSrc : fallbackSrc;
  };

  return (
    <section
      className={`portfolio ${isVisible ? "show-animate" : ""}`}
      id="portfolio"
      ref={portfolioRef}
    >
      <h2 className="heading">
        Latest <span>Project</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>
      <div className="portfolio-container">
        <div className="portfolio-box">
          <img
            src={renderImage(display1Webp, display1)}
            alt="project_image"
            width="384"
            height="201"
          />

          <div className="portfolio-layer">
            <h2>HomeScape Conekt</h2>
            <p>
              My latest project, still in development. This platform is meant to
              help relieve the stress of home and office space search and also
              provide various construction services.
            </p>
            <a
              href="https://homescape-conekt.vercel.app"
              aria-label="Visit this project's Page"
            >
              <i>
                <HiMiniGlobeAmericas />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>

        <div className="portfolio-box">
          <img
            src={renderImage(display3Webp, display3)}
            alt="project_image"
            width="381"
            height="201"
          />

          <div className="portfolio-layer">
            <h2>LinXar Labs Website</h2>
            <p>
              A modern responsive web interface for an emerging tech company.
              The interface is crafted using Next Js and Tailwind CSS, seamless
              animation enhancing the Usee experience.
            </p>
            <a
              href="https://linxar-labs.vercel.app/"
              aria-label="Visit this project's Page"
            >
              <i>
                <HiMiniGlobeAmericas />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 2 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(display5Webp, display5)}
            alt="project_image"
            width="384"
            height="204"
          />
          <div className="portfolio-layer">
            <h2>Sentinel Logistics App</h2>
            <p>
              This is a logistics app for a shipping company, developed with
              Next js and Material UI, responsive with tracking and user
              authentication features.
            </p>
            <a
              href="https://sentinel-logistics.vercel.app/"
              aria-label="Visit this project's Page"
            >
              <i>
                <HiMiniGlobeAmericas />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>

        <div className="portfolio-box">
          <img
            src={renderImage(display2Webp, display2)}
            alt="project_image"
            width="381"
            height="201"
          />
          <div className="portfolio-layer">
            <h2>Gourmet LinX Wep App</h2>
            <p>
              A responsive mobile restaurant UI/UX,developed with react
              reuseable components.
            </p>
            <a
              href="https://gourmet-linx.vercel.app/"
              aria-label="Visit this project's Page"
            >
              <i>
                <HiMiniGlobeAmericas />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 2 }}></span>
        </div>

        <div className="portfolio-box">
          <img
            src={renderImage(display4Webp, display4)}
            alt="project_image"
            width="381"
            height="204"
          />
          <div className="portfolio-layer">
            <h2>Modern Payment UI/UX</h2>
            <p>
              Modern payment UI/UX developed with Tailwind css and Vite. Great
              gradient to bring the design to live.
            </p>
            <a
              href="https://github.com/LinXar-ok/payment-react.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(figmaWebp, figma)}
            alt="project_image"
            width="381"
            height="204"
          />
          <div className="portfolio-layer">
            <h2>FigMa Portfolio UI/UX</h2>
            <p>
              Transform a Figma designs into fully responsive, high-performance
              HTML code. I ensure pixel-perfect accuracy, cross-browser
              compatibility, and seamless user experienc
            </p>
            <a
              href="https://linxar-ok.github.io/PRODIGY-WD-01/"
              aria-label="Visit this project's Page"
            >
              <i>
                <HiMiniGlobeAmericas />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
