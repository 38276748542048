import {
  About,
  Contact,
  Education,
  Home,
  Portfolio,
  Skills,
} from "../../../components";

const MainLayout = () => {
  return (
    <>
      <Home />
      <About />
      <Portfolio />
      <Skills />
      <Education />
      <Contact />
    </>
  );
};

export default MainLayout;
