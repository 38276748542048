import { useEffect } from "react";
import "./App.css";
import DetailedAbout from "./screens/DetailAbout/DetailedAbout";
import MainLayout from "./screens/global/MainLayout/MainLayout";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Footer, Navbar } from "./components";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return null;
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/aboutMe" element={<DetailedAbout />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
