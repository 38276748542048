import React, { useRef } from "react";
import useScrollVisibility from "../useScrollVisibility";
import { MdCalendarMonth } from "react-icons/md";

const Education = () => {
  const educationRef = useRef(null);
  const isVisible = useScrollVisibility(educationRef);

  return (
    <section
      className={`education ${isVisible ? "show-animate" : ""}`}
      id="education"
      ref={educationRef}
    >
      <h2 className="heading">
        My <span>Journey </span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>

      <div className="education-row">
        <div className="education-column">
          <h3 className="title">
            Education
            <span className="animate scroll" style={{ "--i": 2 }}></span>
          </h3>

          <div className="education-box">
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  2021 - Ongoing (Self-paced)
                </div>
                <h3>Self-Taught Programmer</h3>
                <p>
                  As someone who has pursued programming on my own I have been
                  consistently, improving my skills through courses, tutorials
                  and practical projects. My education is an adventure that
                  adapts to the evolving technology landscape.
                </p>
              </div>
            </div>

            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  Ongoing (Self-paced)
                </div>
                <h3>Online Courses and Certifications</h3>
                <p>
                  I am continuously enrolling in online classes to improve my
                  skills across a wide range of subjects. I remain dedicated to
                  staying up-to-date with the latest technologies to ensure that
                  I am at the forefront of innovation.
                </p>
              </div>
            </div>

            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  2021 - Present
                </div>
                <h3>Bachelor Degree - University of the People</h3>
                <p>
                  I am enrolled in a computer science program at University of
                  the People. This program covers a range of computer science
                  topics. It complements my self-taught journey and provides a
                  structured academic foundation for my skills.
                </p>
              </div>
            </div>

            <span className="animate scroll" style={{ "--i": 3 }}></span>
          </div>
        </div>

        <div className="education-column">
          <h3 className="title">
            Experience
            <span className="animate scroll" style={{ "--i": 5 }}></span>
          </h3>

          <div className="education-box">
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  2022 - Present
                </div>
                <h3>Freelance - Web Developer</h3>
                <p>
                  As a freelancer, I have worked with customers on several
                  projects. Using tools like Figma, Next.js, Three.js, React,
                  and Node.js, I've had the chance to work on both front-end and
                  back-end programming to build responsive websites and web
                  apps.
                </p>
              </div>
            </div>

            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  Ongoing
                </div>
                <h3>Hackathons and Coding Challenges</h3>
                <p>
                  I actively participate in coding challenges and hackathons on
                  renowned platforms like LeetCode and Exercism. Engaging in
                  these activities consistently sharpens my problem-solving
                  abilities.
                </p>
              </div>
            </div>

            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i>
                    <MdCalendarMonth />
                  </i>
                  Ongoing
                </div>
                <h3>Personal Projects</h3>
                <p>
                  I've been working on personal projects to apply and showcase
                  my programming skills. Some of these projects includes web
                  applications and mobile apps. They demonstrate my passion for
                  coding and my commitment to continuous learning.
                </p>
              </div>
            </div>

            <span className="animate scroll" style={{ "--i": 6 }}></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
