import React, { useEffect, useState } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "home",
        "about",
        "portfolio",
        "skills",
        "education",
        "contact",
      ];
      let currentSection = activeSection;

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementHeight = rect.bottom - rect.top;
          if (
            rect.top < window.innerHeight * 0.5 &&
            rect.top + elementHeight * 0.5 > 0
          ) {
            currentSection = section;
          }
        }
      });

      setActiveSection(currentSection);

      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    const section = pathname.replace("/", "");
    setActiveSection(section);
  }, [pathname]);

  const handleClick = (path, section) => {
    if (path.startsWith("/#")) {
      // If the path is an internal section, scroll to the section
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // If the path is a different page, navigate to that page
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      <a href="#home" onClick={() => handleClick("/", "home")} className="logo">
        LinXar. <span className="animate" style={{ "--i": 1 }}></span>
      </a>

      <div id="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <MdClose /> : <MdMenu />}
        <span className="animate" style={{ "--i": 2 }}></span>
      </div>

      <nav className={`navbar ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li className={activeSection === "home" ? "active" : ""}>
            <a href="#home" onClick={() => handleClick("/", "home")}>
              Home
            </a>
          </li>
          <li className={activeSection === "about" ? "active" : ""}>
            <a href="#about" onClick={() => handleClick("/", "about")}>
              About
            </a>
          </li>
          <li className={activeSection === "portfolio" ? "active" : ""}>
            <a href="#portfolio" onClick={() => handleClick("/", "portfolio")}>
              Portfolio
            </a>
          </li>
          <li className={activeSection === "skills" ? "active" : ""}>
            <a href="#skills" onClick={() => handleClick("/", "skills")}>
              Skills
            </a>
          </li>
          <li className={activeSection === "education" ? "active" : ""}>
            <a href="#education" onClick={() => handleClick("/", "education")}>
              Education
            </a>
          </li>
          <li className={activeSection === "contact" ? "active" : ""}>
            <a href="#contact" onClick={() => handleClick("/", "contact")}>
              Contact
            </a>
          </li>
        </ul>

        <span className="active-nav"></span>
        <span className="animate" style={{ "--i": 2 }}></span>
      </nav>
    </header>
  );
};

export default Navbar;
